// 默认主题色

@use "sass:math";

// Border color
$border-color-base: #eee; // base border outline a component

// ui颜色规范
$yb-primary-color: #6aaaf5; // 默认色
$yb-gradient-color-1: #62b3ff; // 第1个渐变色
$yb-gradient-color-2: #3d72fa; // 第2个渐变色
$yb-gradient-color: linear-gradient(90deg, $yb-gradient-color-1 0%, $yb-gradient-color-2 100%); // 默认色
$yb-gradient-modal-color: linear-gradient(180deg, #97ccff 0%, #4078fa 100%);

// 按钮
$yb-button-shadow: 0 5px 15px 0 rgba(138, 138, 138, 0.3); // 按钮阴影
$yb-modal-button-shadow: 0px 0.04rem 0.08rem rgba(93, 167, 235, 0.8);

// 颜色规范<适用Y0-Y3>
$yb-strong-color: #414655; // 强调色
$yb-normal-color: #6c7ba8; // 常规色
$yb-negetive-color: #cbced8; // 置灰色
$yb-positive-color: #6aaaf5; // 激活色
$yb-warning-color: #db6372; // 警告色

// 字体颜色
$yb-font-normal-color: #6c7ba8; // 常规色

// 背景色
$yb-bg-color: #f7f9fe;

// 分割线
$yb-divider-color: $border-color-base;

// 平台名称
$yb-platform: 'yb';

// rem换算
@function size($size: 10) {
  @return #{math.div($size, 50)}rem;
}

$yb-strong-line-bg: linear-gradient(180deg, $yb-gradient-color-1 0%, $yb-gradient-color-2 100%); // 标题前置竖线背景
$yb-word-btn-color: #4176fa; //文字按钮颜色
$yb-theme-color: #4176fa; //主题颜色

$yb-activity-bg: linear-gradient(90deg, $yb-gradient-color-1 0%, $yb-gradient-color-2 100%); //首存活动背景色

// 虚拟币存取款推荐交易所
$recommented_exchange_wrapper_bg: rgba(108, 123, 168, 0.16);
$recommented_exchange_wrapper_color: rgb(65, 70, 85);
$recommented_exchange_button_bg: linear-gradient(90deg, $yb-gradient-color-1 0%, $yb-gradient-color-2 100%);
$recommented_exchange_button_color: #fff;

$primary-gradient: linear-gradient(90deg, $yb-gradient-color-1 0%, $yb-gradient-color-2 100%);

$yb-lucency-btn-color: #edf7ff;

// 颜色统一
$primary-10T:#F5F8FF;
$primary-50T:#ECF1FF;
$primary-100T:#D9E4FE;
$primary-200T:#B3C8FD;
$primary-300T:#8DADFC;
$primary-400T:#6791FB;
$primary-500-Base:#4176FA;
$primary-600S:#345EC8;
$primary-700S:#274796;
$primary-800S:#1A2F64;
$primary-900S:#0D1832;
$primary-950S:#070C19;

$secondary-10T:#F8F8FB;
$secondary-50T:#F0F2F6;
$secondary-100T:#E2E5EE;
$secondary-200T:#C4CADC;
$secondary-300T:#A7B0CB;
$secondary-400T:#8995B9;
$secondary-500-Base:#6C7BA8;
$secondary-600S:#566286;
$secondary-700S:#414A65;
$secondary-800S:#2B3143;
$secondary-900S:#161922;
$secondary-950S:#0B0C11;

$tertiary-10T:#FDFBF8;
$tertiary-50T:#FCF8F0;
$tertiary-100T:#F8F0E2;
$tertiary-200T:#F2E2C5;
$tertiary-300T:#EBD3A8;
$tertiary-400T:#E5C58B;
$tertiary-500-Base:#DEB66E;
$tertiary-600S:#B29258;
$tertiary-700S:#856D42;
$tertiary-800S:#59492C;
$tertiary-900S:#2C2416;
$tertiary-950S:#16120B;

$success-100T:#D2EBDA;
$success-200T:#A5D7B5;
$success-300T:#77C48F;
$success-400T:#4AB06A;
$success-500-Base:#1D9C45;
$success-600S:#177D37;
$success-700S:#115E29;
$success-800S:#0C3E1C;
$success-900S:#061F0E;

$warning-100T:#FFEFD5;
$warning-200T:#FFDFAA;
$warning-300T:#FECE80;
$warning-400T:#FEBE55;
$warning-500-Base:#FEAE2B;
$warning-600S:#CB8B22;
$warning-700S:#98681A;
$warning-800S:#664611;
$warning-900S:#332309;

$error-100T:#FAD6D6;
$error-200T:#F4ADAD;
$error-300T:#EF8484;
$error-400T:#E95B5B;
$error-500-Base:#E43232;
$error-600S:#B62828;
$error-700S:#891E1E;
$error-800S:#5B1414;
$error-900S:#2E0A0A;

$grey-50:#FBFBFB;
$grey-100:#F2F2F2;
$grey-200:#EAEAEA;
$grey-300:#D9D9D9;
$grey-400:#AFAFAF;
$grey-500:#8D8D8D;
$grey-600: #626262;
$grey-700:#4E4E4E;
$grey-800:#333333;
$grey-900:#262626;
$grey-950:#191919;
$black: #121212;

$white:#FFFFFF;
$white-90:rgba(255, 255, 255, 0.9);
$white-80:rgba(255, 255, 255, 0.8);
$white-70:rgba(255, 255, 255, 0.7);
$white-60:rgba(255, 255, 255, 0.6);
$white-50:rgba(255, 255, 255, 0.5);
$white-40:rgba(255, 255, 255, 0.4);
$white-30:rgba(255, 255, 255, 0.3);
$white-20:rgba(255, 255, 255, 0.2);
$white-10:rgba(255, 255, 255, 0.1);
$white-8:rgba(255, 255, 255, 0.08);
$white-5:rgba(255, 255, 255, 0.05);
$white-4:rgba(255, 255, 255, 0.04);
$white-2:rgba(255, 255, 255, 0.02);

$theme_Gradient_h_mobile:linear-gradient(269.71deg, #3D72FA 0.3%, #62B3FF 99.8%);
$theme_Gradient_V_mobile:linear-gradient(357.8deg, #3D72FA 1.96%, #62B3FF 98.3%);

.footer_bar_icon_color_normal {
  color: #98a7b5 !important;
}

.footer_bg {
  position: fixed;
  width: 100%;
  height: 1rem;
  left: 0;
  bottom: 0;
  z-index: 100;

  ul {
    display: flex;
    width: 100%;
    height: 100%;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.2);
    background: #fff;

    li {
      position: relative;
      flex: 0.2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0.2rem;
        height: 0.2rem;
        border-radius: 50%;
        background: $yb-primary-color;
        opacity: 0;
        z-index: -1;
      }

      .icon {
        width: 0.48rem;
        height: 0.48rem;

        @if($yb-platform=='yx') {
          width: 0.72rem;
          height: 0.72rem;
        }
      }

      span {
        margin-top: 0.06rem;
        font-size: 0.24rem;
        transform: scale(0.83);

        @if $yb-platform=='yb' {
          color: $yb-normal-color;
        }

        @if $yb-platform=='ky' {
          color: #949ab6;
          font-weight: 400;
          font-family: 'PingFang SC';
        }

        @if $yb-platform=='yx' {
          color: #93A2B1;
          font-family: 'PingFang SC';
          display: block;
          line-height: .28rem;
          margin-top: 0
        }

        @else {
          color: #9aa4c2;
        }

        &.active {
          color: $yb-primary-color;

          @if $yb-platform=='ky' {
            font-weight: 500;
          }


        }
      }

      s {
        display: block;
        width: 0.12rem;
        height: 0.12rem;
        position: absolute;
        border-radius: 50%;
        background: $yb-warning-color;
        top: 0.08rem;
        right: 0.4rem;
      }
    }
  }

  .hidden {
    height: 0;
  }

  &.footer_spring {
    height: 1.04rem;

    ul {
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 0px 0px 0.1rem rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(0.54rem);

      li {
        .icon {
          margin-top: 0.04rem;
          width: 0.72rem;
          height: 0.72rem;
        }

        span {
          margin-top: 0;
          line-height: 0.28rem;
          transform: scale(0.833);
          transform-origin: center center;

          &.active {
            color: $yb-strong-color;

            @if $yb-platform ==hth {
              color: #df4331;
            }

            @if $yb-platform ==yb or $yb-platform ==ayx {
              font-weight: bold;
            }
          }
        }
      }
    }

    @if $yb-platform ==ayx {
      ul {
        background: linear-gradient(170.13deg, #ea4544 0%, #b53032 79.38%);

        li {
          .icon {
            width: 0.64rem;
            height: 0.64rem;
            margin: 0.04rem 0;
          }

          s {
            background: linear-gradient(180deg, #fee4b9 0%, #f1ae75 100%);
          }

          span {
            margin-top: 0;
            line-height: 0.28rem;
            color: #fadbb2;

            &.active {
              color: #fadbb2;
            }
          }
        }
      }
    }

    @if $yb-platform ==leyu {
      ul {
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 0px 0.1rem rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(0.54rem);

        li {
          .icon {
            margin-top: 0;
            width: 0.56rem;
            height: 0.56rem;
          }

          span {
            margin-top: 0;

            &.active {
              color: #cb0e11;
            }
          }
        }
      }
    }
  }
}

.footerScaleBounceAnim {
  @if $yb-platform ==ky {
    .icon {
      animation: footerKyClick 0.5s linear;
    }
  }

  @if ($yb-platform  !=ky and $yb-platform  !=yx) {
    animation: footerClick 0.5s ease-in-out;

    i {
      animation: footerripple 0.5s ease-out;
    }
  }
}

@keyframes footerClick {
  0% {
    transform: scale3d(1, 1, 1);
  }

  20% {
    transform: scale3d(0.6, 0.6, 1);
  }

  40% {
    transform: scale3d(1.2, 1.2, 1);
  }

  60% {
    transform: scale3d(1, 1, 1);
  }

  80% {
    transform: scale3d(1.1, 1.1, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes footerKyClick {
  0% {
    transform: translate(0, 0);
  }

  20% {
    transform: translate(0, -0.01rem);
  }

  40% {
    transform: translate(0, -0.02rem);
  }

  60% {
    transform: translate(0, -0.02rem);
  }

  80% {
    transform: translate(0, -0.01rem);
  }

  100% {
    transform: translate(0, 0px);
  }
}

@keyframes footerripple {
  0% {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale3d(6, 6, 1);
  }
}